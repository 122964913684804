import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Router } from '@abyss/web/ui/Router';
import { Heading } from '@abyss/web/ui/Heading';
import { Button } from '@abyss/web/ui/Button';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Body1,
  Strong,
} from '@src/styles/typography/typography';

const HelloAbyssContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const ButtonContainer = styled('div', {
  padding: '20px 0',
});

export const HelloAbyss = () => {
  return (
    <React.Fragment>
      <Router.MetaTags title="Hello" />
      <ButtonContainer alignLayout="center">
        <Heading offset={1}>Hello!</Heading>
        <Button variant="outline" href="/">
          Go to Home
        </Button>
      </ButtonContainer>
      <HelloAbyssContainer>
        <H1>H1 Heading</H1>
        <H2>H2 Test</H2>
      </HelloAbyssContainer>
      <HelloAbyssContainer>
        <H3>H3 Test</H3>
        <H4>H4 Test</H4>
      </HelloAbyssContainer>
      <HelloAbyssContainer>
        <H5>H5 Test</H5>
        <H6>H6 Test</H6>
      </HelloAbyssContainer>
      <HelloAbyssContainer>
        <Body1>body1 test</Body1>
        <Strong>body1 bold test</Strong>
      </HelloAbyssContainer>
    </React.Fragment>
  );
};
