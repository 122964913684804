type BreakpointProps = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

export const breakpoint: BreakpointProps = {
  xs: '@screen >= $xs',
  sm: '@screen >= $sm',
  md: '@screen >= $md',
  lg: '@screen >= $lg',
  xl: '@screen >= $xl',
};
