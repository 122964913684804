import React from 'react';
import { Card as AbyssCard } from '@abyss/web/ui/Card';
import { Layout } from '@abyss/web/ui/Layout';
import { Box } from '@abyss/web/ui/Box';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@src/common/button/button';
import { Body1, H2 } from '@src/styles/typography/typography';
import { breakpoint } from '@src/styles/breakpoints';

type BgColor = '$white' | '$beige1' | '$beige3' | '$interactive1';

type CardProps = {
  gap?: string;
  numOfCards: number;
  content: {
    title?: string;
    description: string;
    buttonText?: string;
    cardBanner?: string;
  };
  isIcon?: boolean;
  onClick?: () => void;
  topBgColor?: BgColor;
  bottomBgColor?: BgColor;
};

const BORDER_RADIUS = '4px';
const isImageUrl = (url: string | undefined) => {
  return /\.(jpg|jpeg|png|svg|bmp)$/i.test(url || '');
};

/**
 * @param isIcon: boolean, might want to use this to make the height smaller if there's an icon present?
 * @returns
 */
export const Card = ({
  content: { title = '', description, buttonText = '', cardBanner },
  isIcon = false,
  onClick,
  numOfCards,
  gap,
  topBgColor = '$beige3',
  bottomBgColor = '$beige1',
}: CardProps) => {
  const backgroundImage = isImageUrl(cardBanner) && `url(${cardBanner})`;

  return (
    <AbyssCard
      css={{
        'abyss-card-root': {
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0 !important',
          flexGrow: '1',
          maxHeight: '600px',
          border: 'none',
          borderRadius: BORDER_RADIUS,
          [`${breakpoint.lg}`]: {
            maxWidth: `calc(${100 / numOfCards}% - ${gap})`,
          },
        },
      }}
    >
      {/* Top of the card */}
      <AbyssCard.Section
        css={{
          'abyss-card-section': {
            padding: '0px',
          },
        }}
      >
        <Box
          height={
            // eslint-disable-next-line no-nested-ternary
            isImageUrl(cardBanner) && isIcon
              ? '110px'
              : isImageUrl(cardBanner) && !isIcon
              ? '180px'
              : undefined
          }
          padding="0px"
          color={topBgColor}
          css={{
            'abyss-box-root': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundPosition: 'center',
              backgroundImage,
              backgroundRepeat: 'no-repeat',
              borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
            },
          }}
        >
          {!isImageUrl(cardBanner) && (
            <H2 textAlign="center" css={{ margin: 10, fontSize: '50px' }}>
              {cardBanner}
            </H2>
          )}
        </Box>
      </AbyssCard.Section>
      {/* Bottom of the card */}
      <AbyssCard.Section
        css={{
          'abyss-card-section': {
            borderTop: 'none !important',
            backgroundColor: bottomBgColor,
            borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
            flex: 1,
          },
        }}
      >
        <Layout.Group alignLayout="center">
          <Layout.Stack>
            <Text size="24px" fontWeight="$bold" color="$primary1">
              {title}
            </Text>
            <Body1
              css={{
                textAlign: 'center',
                color: '$gray7',
                marginBottom: '5px',
              }}
            >
              {description}
            </Body1>
            {buttonText && onClick && (
              <Button
                variant="outline"
                onClick={() => {
                  onClick();
                }}
              >
                {buttonText}
              </Button>
            )}
          </Layout.Stack>
        </Layout.Group>
      </AbyssCard.Section>
    </AbyssCard>
  );
};

Card.defaultProps = {
  gap: '5px',
  onClick: null,
  topBgColor: '$beige3',
  bottomBgColor: '$white',
  isIcon: false,
};
