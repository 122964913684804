import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { BroadenHealthLogo } from '@src/common/logo/logo';
import { PageFooter } from '@abyss/web/ui/PageFooter';

import { FooterContent } from './footer.content';
import { ContainerStyles } from '../layout/container';

const LogoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  marginBottom: '$lg',
  marginLeft: 'auto',

  '@media (max-width: 464px)': {
    marginLeft: 0,
  },
});

export const Footer = () => {
  const { copyright, columns } = FooterContent;
  return (
    <PageFooter
      subFooterTitle={copyright}
      css={{
        'abyss-page-footer-root': {
          backgroundColor: '$primary1',
        },
        'abyss-page-footer-links': {
          borderBottom: '1px solid $gray5',
        },
        'abyss-page-footer-container': {
          ...ContainerStyles,
          padding: '$md 0',
        },
      }}
    >
      {columns.map((section) => {
        const { name, links } = section;

        return (
          <PageFooter.Section
            title={name}
            key={name}
            css={{
              'abyss-page-footer-list-container': {
                listStyle: 'none',
                paddingLeft: 0,
              },
            }}
          >
            {links.map((link) => {
              return (
                <PageFooter.Link
                  css={{
                    textDecoration: 'none',

                    '&:hover': {
                      color: '$white',
                    },
                  }}
                  key={link.linkTitle}
                  href={link.linkHref}
                >
                  {link.linkTitle}
                </PageFooter.Link>
              );
            })}
          </PageFooter.Section>
        );
      })}
      <LogoContainer>
        <BroadenHealthLogo color="white" variant="footer" />
      </LogoContainer>
    </PageFooter>
  );
};
