import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@src/common/layout/layout';

// eslint-disable-next-line
import { Outlet } from 'react-router';

const ContentContainer = styled('main', {
  minHeight: '80vh',
});

export const AppBody = () => {
  return (
    <Layout>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Layout>
  );
};
