/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@src/common/button/button';
import { Icons } from '@src/common/icons/icons';

export const ContactUsButton = ({
  variant = 'primaryBroaden',
  css,
  ...props
}: {
  variant?: string;
  css: { [k: string]: object | string | number };
}) => {
  return (
    <Button
      {...props}
      css={{
        'span.abyss-insert-element-after': {
          paddingLeft: '3px',
        },
        ...css,
      }}
      size={variant === 'outline' ? '40px' : '48px'}
      variant={variant}
      href="https://uhgenterprise.qualtrics.com/jfe/form/SV_9S2mJSrtkZa6KlU"
      after={<Icons icon="keyboard_arrow_right" />}
    >
      Contact us
    </Button>
  );
};

ContactUsButton.propTypes = {
  css: PropTypes.object,
};

ContactUsButton.defaultProps = {
  variant: 'primaryBroaden',
  css: {},
};
