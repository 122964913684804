// TODO Guarantee that the link is an HTTPs link instead of just a string
export type LinkProps = {
  linkTitle: string;
  linkHref: string;
};

type ColumnProps = {
  name: string;
  links: LinkProps[];
};

type FooterContentProps = {
  copyright: string;
  columns: ColumnProps[];
};

export const FooterContent: FooterContentProps = {
  copyright: 'Broaden Health',
  columns: [
    {
      name: 'Support',
      links: [
        {
          linkTitle: 'Help & Contact us',
          linkHref:
            'https://uhgenterprise.qualtrics.com/jfe/form/SV_9S2mJSrtkZa6KlU',
        },
      ],
    },
  ],
};
