import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { RouterProvider } from '@abyss/web/ui/RouterProvider';
import { globalStyles } from '@src/styles/globalStyles';
import { Routes } from './routes';

import { themeOverride } from './styles/theme';

const theme = createTheme('optum', themeOverride);
const router = createRouter(Routes);

// As of 09/05/2023, this is the best way to remove
// the default stylings
const customTheme = {
  ...theme,
  themeVariantStyles: null,
  themeVariantCss: null,
};
globalStyles();
export const browser = () => {
  return (
    <ThemeProvider theme={customTheme} includeBaseCss={false}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
