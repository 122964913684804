import React from 'react';
import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu/NavMenuPrimitives';
import { breakpoint } from '@src/styles/breakpoints';
import { ContactUsButton } from '../button/contact-us-button';

export const NavMenu = () => {
  return (
    <React.Fragment>
      <NavMenuPrimitives.Root
        css={{
          justifyContent: 'center',
          [`${breakpoint.sm}`]: { justifyContent: 'end' },
        }}
        zIndex={202}
        hasOverlay
      >
        <ContactUsButton variant="outline" />
        <NavMenuPrimitives.Viewport />
      </NavMenuPrimitives.Root>
    </React.Fragment>
  );
};
