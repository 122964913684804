import { styled } from '@abyss/web/tools/styled';
import { Button as AbyssButton } from '@abyss/web/ui/Button';
import { body1Styles } from '@src/styles/typography/typography';

import { breakpoint } from '@src/styles/breakpoints';

export const PrimaryButtonStyles = {
  backgroundColor: '$primary3',
  transition: 'none',
  border: 'none',
  svg: {
    fill: '$white',
  },

  '&:hover > span': {
    backgroundColor: '$interactive3',
    fill: '$primary3',
    border: 'none',
  },

  '&:hover': {
    backgroundColor: '$interactive3',
    color: '$white',
    fill: '$white',
  },
};

const OutlineStyles = {
  color: '$primary3',
  backgroundColor: '$white',
  border: 'solid 1px $primary3',
  svg: {
    fill: '$primary3',
  },
  '& > span': {
    border: 'none',
  },

  '&:hover > span': {
    backgroundColor: '$white',
    color: '$interactive3',
    border: 'none',
  },

  '&:hover,&:active': {
    border: 'solid 1px $interactive3',
    color: '$interactive3',
    svg: {
      fill: '$interactive3',
    },
    backgroundColor: '$white',
  },
};

export const Button = styled(AbyssButton, {
  ...body1Styles,
  fontSize: '$mobile_body1',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_body2',
    lineHeight: '$desktop_body1',
  },
  variants: {
    variant: {
      primaryBroaden: PrimaryButtonStyles,
      outline: OutlineStyles,
    },
  },
});
