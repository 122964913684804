import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Body1 } from '@src/styles/typography/typography';
import { ContainerStyles } from '@src/common/layout/container';
import { SectionWrapper } from '@src/common/layout/section-wrapper';

const Container = styled('div', {
  ...ContainerStyles,
  padding: '2rem 0',
  background: 'F0EFEB',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '45rem',
  gap: '1rem',
  textAlign: 'center',
});

export const ContactUsInfoSection = () => {
  return (
    <SectionWrapper>
      <Container>
        <Body1>
          As you look to expand your benefits portfolio, make sure every
          ancillary benefit you offer meets the needs of your members and the
          goals of your business, seamlessly layered into the foundation you
          established.
        </Body1>
        <Body1>
          At Broaden Health, our solutions are customized to your needs. Proven
          capabilities that meet your members where they are. Expertise and
          support designed to complement your team. A single, dedicated account
          team ready to help you implement and grow your program on your terms.
          Based on your preference, your brand can remain at the center of it
          all while we handle the details.
        </Body1>
      </Container>
    </SectionWrapper>
  );
};
