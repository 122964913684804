import { globalCss } from '@abyss/web/tools/styled';

export const globalStyles = globalCss({
  '*': {
    margin: '0',
    fontFamily: '$primary',
  },
  'h1, h2': {
    fontFamily: '$heading',
  },
  '.abyss-theme-provider-root': {
    backgroundColor: '#FAF8F2',
  },
});
