/**
 * Utilizes Stitches, in which there are theme tokens
 * You can reference the colors using `$`
 * e.g $desktop_h1
 * For more info, check https://abyss.optum.com/web/tools/create-theme/#usage
 */
const colors = {
  primary1: '#2A602F',
  primary3: '#A2310B',
  interactive1: '#D0E3D2',
  interactive3: '#A06060',
  beige1: '#F0EFEB', // Official color given by design team
  beige2: '#ECECE5',
  beige3: '#FAF8F2',
  linden: '#D0E3D2',
  footerText: '#fff',
};

const fontSizes = {
  desktop_h1: '48px',
  desktop_h2: '36px',
  desktop_h3: '28.83px',
  desktop_h4: '25.63px',
  desktop_h5: '22.78px',
  desktop_h6: '20.25px',
  desktop_body1: '18px',
  desktop_body2: '16px',
  mobile_h1: '36px',
  mobile_h2: '28px',
  mobile_h3: '25.63px',
  mobile_h4: '22.78px',
  mobile_h5: '20.25px',
  mobile_h6: '18px',
  mobile_body1: '16px',
  mobile_body2: '16px',
};

const lineHeights = {
  desktop_h1: '57.6px',
  desktop_h2: '43.2px',
  desktop_h3: '40.36px',
  desktop_h4: '32px',
  desktop_h5: '28px',
  desktop_h6: '24px',
  desktop_body1: '24px',
  desktop_body2: '20px',
  mobile_h1: '43.2px',
  mobile_h2: '33.6px',
  mobile_h3: '35.8px',
  mobile_h4: '28px',
  mobile_h5: '24px',
  mobile_h6: '20px',
  mobile_body1: '20px',
  mobile_body2: '20px',
};

const fontWeights = {
  lighter: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  xbold: 800,
};

const fonts = {
  primary: 'OptumSans, Arial, sans-serif',
  heading: 'Orleans, Arial, sans-serif',
};

const css = {
  '.abyss-theme-provider-root': {
    fontFamily: '$primary',
    fontSize: '$md',
    fontWeight: '$normal',
    color: '$foreground',
    ':focus-visible': {
      focusRing: '$interactive1',
    },
    h1: {
      fontFamily: '$heading',
      fontSize: '$mobile_h1',
      lineHeight: '$mobile_h1',
      color: '$primary1',
      fontWeight: '$xbold',
      '@screen >= $md': {
        fontSize: '$desktop_h1',
        lineHeight: '$desktop_h1',
      },
    },
    h2: {
      fontFamily: '$primary',
      fontSize: '$mobile_h2',
      lineHeight: '$mobile_h2',
      color: '$primary1',
      fontWeight: '$xbold',
      '@screen >= $md': {
        fontSize: '$desktop_h2',
        lineHeight: '$desktop_h2',
      },
    },
    h3: {
      fontFamily: '$primary',
      fontSize: '$mobile_h3',
      lineHeight: '$mobile_h3',
      color: '$gray8',
      fontWeight: '$normal',
      '@screen >= $md': {
        fontSize: '$desktop_h3',
        lineHeight: '$desktop_h3',
      },
    },
    h4: {
      fontFamily: '$primary',
      fontSize: '$mobile_h4',
      lineHeight: '$mobile_h4',
      color: '$gray8',
      fontWeight: '$xbold',
      '@screen >= $md': {
        fontSize: '$desktop_h4',
        lineHeight: '$desktop_h4',
      },
    },
    h5: {
      fontFamily: '$primary',
      fontSize: '$mobile_h5',
      lineHeight: '$mobile_h5',
      color: '$gray8',
      fontWeight: '$xbold',
      '@screen >= $md': {
        fontSize: '$desktop_h5',
        lineHeight: '$desktop_h5',
      },
    },
    h6: {
      fontFamily: '$primary',
      fontSize: '$mobile_h6',
      lineHeight: '$mobile_h6',
      color: '$gray8',
      fontWeight: '$xbold',
      '@screen >= $md': {
        fontSize: '$desktop_h6',
        lineHeight: '$desktop_h6',
      },
    },
    'h1[data-display="1"]': {
      fontSize: '3.246rem',
      lineHeight: '4rem',
      fontWeight: '$semibold',
    },
    'h1[data-display="2"]': {
      fontSize: '2.886rem',
      lineHeight: '3.5rem',
      fontWeight: '$semibold',
    },
    'h1[data-display="3"]': {
      fontSize: '2.565rem',
      lineHeight: '3.25rem',
      fontWeight: '$semibold',
    },
  },
};

export const themeOverride = {
  theme: {
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
  },
  css,
};
