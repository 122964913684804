import React, { PropsWithChildren } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { breakpoint } from '@src/styles/breakpoints';

export const ContainerStyles = {
  width: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',

  [`${breakpoint.xs}`]: {
    maxWidth: '340px',
  },

  [`${breakpoint.sm}`]: {
    maxWidth: '540px',
  },

  [`${breakpoint.md}`]: {
    maxWidth: '540px',
  },

  [`${breakpoint.lg}`]: {
    maxWidth: '960px',
    paddingLeft: 0,
  },

  [`${breakpoint.xl}`]: {
    maxWidth: '1272px',
  },
};

const SContainer = styled('div', ContainerStyles);

export type ContainerProps = PropsWithChildren<{
  className?: string;
  css?: { [key: string]: string | number };
}>;

export const Container = ({ children, className, css }: ContainerProps) => {
  return (
    <SContainer className={className} css={css}>
      {children}
    </SContainer>
  );
};
