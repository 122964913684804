import { styled } from '@abyss/web/tools/styled';
import { breakpoint } from '@src/styles/breakpoints';

// Check Abyss - https://abyss.optum.com/web/hooks/use-media-query
// OR responsive styling - https://stitches.dev/docs/responsive-styles
// But using `@media [...]` was the only way
// Ideally, we would be able to reference `@sm` instead

const textAlignVariant = {
  textAlign: {
    center: {
      textAlign: 'center',
    },
    start: {
      textAlign: 'start',
    },
    end: {
      textAlign: 'end',
    },
    justify: {
      textAlign: 'justify',
    },
    left: {
      textAlign: 'left',
    },
    right: {
      textAlign: 'right',
    },
  },
};

export const h1Styles = {
  fontSize: '$mobile_h1',
  lineHeight: '$mobile_h1',
  color: '$primary1',
  fontWeight: '$xbold',
  marginBottom: '1rem',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_h1',
    lineHeight: '$desktop_h1',
  },
  variants: {
    ...textAlignVariant,
  },
};

export const h2Styles = {
  fontSize: '$mobile_h2',
  lineHeight: '$mobile_h2',
  color: '$primary1',
  fontWeight: '$xbold',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_h2',
    lineHeight: '$desktop_h2',
  },
  variants: {
    ...textAlignVariant,
  },
};

export const h3Styles = {
  fontFamily: '$primary',
  fontSize: '$mobile_h3',
  lineHeight: '$mobile_h3',
  color: '$gray8',
  fontWeight: '$normal',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_h3',
    lineHeight: '$desktop_h3',
  },
  variants: {
    ...textAlignVariant,
  },
};

export const h4Styles = {
  fontFamily: '$primary',
  fontSize: '$mobile_h4',
  lineHeight: '$mobile_h4',
  color: '$gray8',
  fontWeight: '$xbold',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_h4',
    lineHeight: '$desktop_h4',
  },
  variants: {
    ...textAlignVariant,
  },
};

export const h5Styles = {
  fontFamily: '$primary',
  fontSize: '$mobile_h5',
  lineHeight: '$mobile_h5',
  color: '$gray8',
  fontWeight: '$xbold',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_h5',
    lineHeight: '$desktop_h5',
  },
  variants: {
    ...textAlignVariant,
  },
};

export const h6Styles = {
  fontFamily: '$primary',
  fontSize: '$mobile_h6',
  lineHeight: '$mobile_h6',
  color: '$gray8',
  fontWeight: '$xbold',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_h6',
    lineHeight: '$desktop_h6',
  },
  variants: {
    ...textAlignVariant,
  },
};

export const body1Styles = {
  fontFamily: '$primary',
  fontSize: '$mobile_body1',
  lineHeight: '$mobile_body1',
  [`${breakpoint.md}`]: {
    fontSize: '$desktop_body1',
    lineHeight: '$desktop_body1',
  },
  variants: {
    ...textAlignVariant,
    fontSize: {
      lg: {
        fontSize: '22px',
      },
    },
  },
};

export const H1 = styled('h1', {
  ...h1Styles,
});

export const H2 = styled('h2', {
  ...h2Styles,
});

export const H3 = styled('h3', {
  ...h3Styles,
});

export const H4 = styled('h4', {
  ...h4Styles,
});

export const H5 = styled('h5', {
  ...h5Styles,
});

export const H6 = styled('h6', {
  ...h6Styles,
});

export const Body1 = styled('p', {
  ...body1Styles,
});

export const Strong = styled(Body1, {
  fontWeight: '$xbold',
});
