import React, { ReactNode, PropsWithChildren } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@src/common/button/button';
import { body1Styles, h1Styles } from '@src/styles/typography/typography';
import { ContactUsButton } from '../button/contact-us-button';

type SModalProps = PropsWithChildren<
  {
    footer: boolean | ReactNode;
  } & typeof Modal
>;

export const SModal = ({
  children,
  title,
  isOpen,
  onClose,
  footer,
  ...props
}: SModalProps) => {
  return (
    <Modal
      {...props}
      size="full"
      titleAlign="center"
      css={{
        'abyss-modal-content-title': {
          ...h1Styles,
        },
        // By changing the close icon + button
        // We're able to get a more 'preferred' style
        'abyss-modal-close-button': {
          border: 'none',
          backgroundColor: '$white',

          '&:hover': {
            backgroundColor: '$white',
          },
        },
        'abyss-modal-close-icon': {
          fill: '$primary1',
          border: 'none', // NOTE Safari has this weird ring around the icon and this removes that

          '&:hover': {
            fill: '$interactive3',
            backgroundColor: '$white',
          },
        },
        'abyss-modal-body': {
          ...body1Styles,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        footer === false ? null : (
          <Modal.Section>
            <Layout.Group alignLayout="right">
              <ContactUsButton variant="primaryBroaden" />
              <Button variant="outline" onClick={onClose}>
                Close
              </Button>
            </Layout.Group>
          </Modal.Section>
        )
      }
    >
      {children}
    </Modal>
  );
};
