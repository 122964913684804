import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { DiscoverSection } from './discover-section';
import { ContactUsInfoSection } from './contact-us-info';
import { MarketingBannerWithBackground } from './marketing-banner';

export const Home = () => {
  return (
    <React.Fragment>
      <Router.MetaTags title="Home" />
      <MarketingBannerWithBackground />
      <ContactUsInfoSection />
      <DiscoverSection />
    </React.Fragment>
  );
};
