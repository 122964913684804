/* eslint-disable react/prop-types */
import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Body1, H1 } from '@src/styles/typography/typography';
import { SectionWrapper } from '@src/common/layout/section-wrapper';
import { ContactUsButton } from '@src/common/button/contact-us-button';
import { breakpoint } from '@src/styles/breakpoints';

const GradientBackground = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: ['linear-gradient(180deg, $beige2, $beige1, $white, $beige2)'],
  zIndex: -2,
});

const PageContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '50vh',
  zIndex: 1,
});

const Image = styled('img', {
  width: '500px',
  height: 'auto',
  position: 'absolute',
  bottom: '0',
  right: '0',
  zIndex: -1,
});

const Card = styled('div', {
  backgroundColor: '$white',
  padding: '3rem',
  borderRadius: '4px',
  width: '503px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
});

export const MarketingBannerWithBackground = () => {
  return (
    <SectionWrapper background="beigeGradient">
      <GradientBackground />
      <PageContainer>
        <Card>
          <H1
            css={{
              marginBottom: '2rem',
              [`${breakpoint.md}`]: { whiteSpace: 'nowrap' },
            }}
          >
            Your benefits, your way
          </H1>
          <Body1 textAlign="center" css={{ marginBottom: '1rem' }}>
            Take command of your portfolio with a broad range of benefit
            solutions designed to be owned by you and delivered by Broaden
            Health.
          </Body1>
          <ContactUsButton css={{ margin: 'auto' }} />
        </Card>
        <Image src="/static/marketing-banner.svg" />
      </PageContainer>
    </SectionWrapper>
  );
};
