import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { AppBody } from '../common/AppBody';
import { Home } from './Home';
import { HelloAbyss } from './HelloAbyss';

export const Routes = () => {
  return (
    <Router.Routes title="{{title}} | BroadenHealth">
      <Router.Route path="/" element={<AppBody />}>
        <Router.Route path="/hello-abyss" element={<HelloAbyss />} />
        <Router.Route path="/" element={<Home />} />
      </Router.Route>
    </Router.Routes>
  );
};
