import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { breakpoint } from '@src/styles/breakpoints';

type BroadenHealthLogoProps = {
  variant?: string;
  color?: string;
};

const Svg = styled('svg', {
  variants: {
    variant: {
      header: {
        maxWidth: '300px',
        [`${breakpoint.md}`]: {
          maxWidth: '406.72px',
        },
      },
      footer: {
        maxWidth: '150px',
        [`${breakpoint.md}`]: {
          maxWidth: '200px',
        },
      },
    },
  },
});

export const BroadenHealthLogo = ({
  variant,
  color,
}: BroadenHealthLogoProps) => {
  if (color === 'green' || color === 'primary1') {
    // eslint-disable-next-line no-param-reassign
    color = '#2A602F';
  }

  return (
    <Svg
      variant={variant}
      height="36"
      viewBox="0 0 327 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M313.484 19.5567C313.484 16.2578 311.36 15.3904 308.955 15.3904C307.527 15.3904 306.05 16.5075 304.476 17.7377V35.2574H297.928V1.42725L304.428 0.441528V15.5665C305.611 14.1891 306.741 13.0562 307.777 12.1204C308.91 11.0874 310.485 10.0517 313.092 10.0517C317.474 10.0517 320.032 12.2676 320.032 17.0411V35.2547H313.484V19.5541V19.5567Z"
        fill={color}
      />
      <path
        d="M323.593 32.8387V33.1253H322.788V35.2308H322.46V33.1253H321.655V32.8387H323.593Z"
        fill={color}
      />
      <path
        d="M323.885 32.8387H324.35L325.039 34.8601L325.722 32.8387H326.182V35.2308H325.872V33.8192C325.872 33.7693 325.872 33.6904 325.875 33.5774C325.875 33.4644 325.877 33.3434 325.877 33.2146L325.194 35.2308H324.873L324.184 33.2146V33.2882C324.184 33.3461 324.184 33.4354 324.19 33.5564C324.192 33.6773 324.195 33.764 324.195 33.8218V35.2334H323.885V32.8414V32.8387Z"
        fill={color}
      />
      <path
        d="M276.668 30.2867C275.438 30.2867 274.599 29.598 274.599 27.9236V0L268.102 0.935782V30.7757H268.109V30.8755C268.207 33.583 270.17 35.31 273.714 35.31C274.352 35.31 275.682 35.1129 276.077 34.9157L277.701 30.1422C277.504 30.2394 277.013 30.2894 276.668 30.2894"
        fill={color}
      />
      <path
        d="M207.652 19.3359C207.652 16.037 205.528 15.1695 203.123 15.1695C201.695 15.1695 200.218 16.2867 198.644 17.5169V34.8158H192.096V0.985725L198.594 0V15.125C199.776 13.7476 200.907 12.6147 201.942 11.6789C203.075 10.6458 204.65 9.61017 207.257 9.61017C211.639 9.61017 214.197 11.8261 214.197 16.5996V34.8132H207.649V19.1126L207.652 19.3359Z"
        fill={color}
      />
      <path
        d="M246.588 10.843C248.016 10.1043 251.018 9.61279 252.989 9.61279C259.34 9.61279 262.194 12.3702 262.194 16.208V27.9236C262.194 29.598 263.03 30.2867 264.261 30.2867C264.605 30.2867 265.096 30.2368 265.294 30.1395L263.669 34.9131C263.275 35.1102 261.947 35.3074 261.306 35.3074C257.763 35.3074 255.841 33.583 255.744 30.8782V30.7783H255.647C255.202 31.764 254.366 32.6499 253.578 33.3859C252.25 34.5661 250.723 35.3047 248.557 35.3047C245.208 35.3047 242.748 33.1388 242.748 29.4482C242.748 27.9709 243.045 25.203 243.437 24.0701L255.594 19.4911V18.0269C255.594 15.3694 253.725 14.3338 250.523 14.3338C248.555 14.3338 245.75 15.1223 243.878 16.0082L243.781 15.9582C245.061 13.5452 246.094 11.6263 246.586 10.8377M248.999 27.5241C248.999 30.4786 249.885 31.1805 251.903 31.1805C253.428 31.1805 254.711 29.7454 255.594 28.3995V22.7321L249.49 25.1794C249.146 25.8207 248.999 26.4411 248.999 27.5241Z"
        fill={color}
      />
      <path
        d="M287.89 3.69604V10.0546H295.176L294.241 14.2867H287.89V27.6768C287.89 29.9899 289.514 30.8758 291.778 30.8758C293.008 30.8758 294.238 30.6287 294.927 30.2843C294.877 30.6786 294.68 32.3031 294.582 32.5003C293.599 34.0275 291.531 35.3076 288.037 35.3076C285.527 35.3076 281.342 33.9775 281.342 29.6955V14.2893H277.552L277.354 13.6979L281.342 10.2018V5.91196L287.89 3.69604Z"
        fill={color}
      />
      <path
        d="M224.435 23.8546L239.449 19.1494C239.449 19.1494 239.549 18.6184 239.549 18.1768C239.599 12.5148 235.956 9.61279 230.344 9.61279C227.833 9.61279 225.52 10.4487 223.452 11.7788C220.647 13.6503 217.742 17.2436 217.742 23.2973C217.742 30.9281 222.96 35.3074 229.114 35.3074C234.087 35.3074 236.792 33.93 238.563 32.0085C238.711 31.5169 239.449 28.3652 239.499 27.9709C238.416 28.9067 235.462 30.0397 232.163 30.0397C227.944 30.0397 225.228 27.8921 224.432 23.8546M229.718 12.6935C233.614 12.6935 235.251 15.6428 235.251 16.6811L224.098 20.4295C223.927 16.5628 225.904 12.6935 229.718 12.6935Z"
        fill={color}
      />
      <path
        d="M0 0.959464L6.4979 0.0236816V14.3627C7.481 13.3297 8.81107 12.1967 9.944 11.3608C11.2241 10.4251 13.143 9.58916 15.0645 9.58916C20.7265 9.58916 24.5643 13.5767 24.5643 20.6161C24.5643 26.1783 22.3011 30.2158 19.0994 32.5789C16.8362 34.4005 13.7344 35.2864 10.5354 35.2864C7.33643 35.2864 2.41306 34.3506 0 33.1204V0.959464ZM6.54785 30.7494C8.41941 32.0295 10.2384 32.8076 11.7157 32.8575C15.5561 32.6604 17.6721 30.5575 17.6721 22.238C17.6721 16.873 15.606 14.9831 10.977 14.9831C9.54971 14.9831 7.72809 15.7427 6.54785 16.4814V30.7494Z"
        fill={color}
      />
      <path
        d="M28.5524 10.7195L35.0503 9.78369V15.2959C35.789 13.9658 36.872 12.4412 37.7578 11.4082C38.7908 10.2752 40.1209 9.58655 41.6954 9.58655C42.6312 9.58655 43.7641 9.88095 44.5501 10.128L42.8757 16.5287C40.9069 15.79 38.938 15.1513 38.2493 15.1513C37.2636 15.1513 36.0334 16.6363 35.0977 17.4249V34.7896H28.5498V10.7195H28.5524Z"
        fill={color}
      />
      <path
        d="M58.2846 9.58655C64.9797 9.58655 70.986 14.2129 70.986 21.941C70.986 27.7002 67.9841 31.0491 65.1768 33.0179C63.0608 34.4952 60.3034 35.2811 57.8404 35.2811C51.1453 35.2811 45.1416 30.6548 45.1416 22.9267C45.1416 17.1674 48.1934 13.8212 50.9508 11.8498C53.0668 10.3252 55.8742 9.58655 58.2846 9.58655ZM58.629 32.1612C60.9104 32.1612 63.9939 30.8963 63.9939 21.5437C63.9939 15.6158 60.9842 12.7249 57.567 12.7249C54.8415 12.7249 52.1311 14.2344 52.1311 22.8294C52.1311 28.9101 55.2013 32.1586 58.629 32.1586"
        fill={color}
      />
      <path
        d="M77.2469 10.8167C78.6743 10.0781 81.6788 9.58655 83.6476 9.58655C89.9983 9.58655 92.8529 12.344 92.8529 16.1817V27.8974C92.8529 29.5718 93.6888 30.2605 94.9216 30.2605C95.266 30.2605 95.7576 30.2106 95.9547 30.1133L94.3302 34.8868C93.9359 35.084 92.6059 35.2811 91.9671 35.2811C88.4238 35.2811 86.5022 33.5568 86.405 30.8519V30.752H86.3051C85.8635 31.7378 85.025 32.6236 84.239 33.3596C82.9089 34.5399 81.3844 35.2785 79.2184 35.2785C75.8722 35.2785 73.4092 33.1125 73.4092 29.422C73.4092 27.9447 73.7036 25.1768 74.0979 24.0439L86.2578 19.4648V18.0007C86.2578 15.3432 84.3862 14.3075 81.1872 14.3075C79.2184 14.3075 76.4137 15.0961 74.5421 15.9819L74.4422 15.932C75.7224 13.5189 76.7554 11.6001 77.2469 10.8115M79.66 27.4978C79.66 30.4524 80.5458 31.1542 82.5646 31.1542C84.0892 31.1542 85.4298 29.7454 86.2552 28.3733V22.7059L80.1515 25.1531C79.8072 25.7945 79.66 26.4149 79.66 27.4978Z"
        fill={color}
      />
      <path
        d="M113.035 33.6593C111.852 34.595 109.836 35.2837 107.964 35.2837C102.255 35.2837 97.8232 30.8046 97.8232 23.2737C97.8232 14.6597 104.371 9.58916 111.949 9.58916C113.082 9.58916 114.904 9.88356 115.593 10.0807V1.00941L122.143 0.0236816V34.348L115.645 35.0866V31.0491C115.056 31.885 114.365 32.6236 113.038 33.6566M104.768 21.2523C104.768 27.8974 107.081 30.5551 111.069 30.5551C112.94 30.5551 114.515 29.225 115.598 28.5863V14.5598C114.265 13.8528 111.453 12.3781 110.43 12.5437C107.163 13.0773 104.768 14.7544 104.768 21.2523Z"
        fill={color}
      />
      <path
        d="M151.341 10.7195L157.839 9.78369V15.1014C159.022 13.6241 160.152 12.591 161.188 11.6553C162.418 10.5723 164.042 9.58655 166.652 9.58655C171.034 9.58655 173.445 11.8025 173.445 16.576V34.7896H166.897V19.3096C166.897 16.0107 164.263 15.1433 162.368 15.1433C160.94 15.1433 159.463 16.2605 157.889 17.4906V34.7896H151.341V10.7195Z"
        fill={color}
      />
      <path
        d="M132.532 23.831L147.547 19.1258C147.547 19.1258 147.647 18.5948 147.647 18.1532C147.697 12.4912 144.053 9.58923 138.441 9.58923C135.931 9.58923 133.618 10.4251 131.549 11.7552C128.744 13.6268 125.84 17.2201 125.84 23.2737C125.84 30.9046 131.058 35.2838 137.211 35.2838C142.184 35.2838 144.889 33.9064 146.661 31.9849C146.808 31.4934 147.547 28.3417 147.597 27.9474C146.514 28.8832 143.559 30.0161 140.26 30.0161C136.041 30.0161 133.326 27.8685 132.53 23.831M137.816 12.67C141.711 12.67 143.349 15.6192 143.349 16.6575L132.196 20.4059C132.025 16.5393 134.002 12.67 137.816 12.67Z"
        fill={color}
      />
    </Svg>
  );
};

BroadenHealthLogo.defaultProps = {
  variant: 'header',
  color: 'white',
};
