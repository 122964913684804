/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';

export const Icons = ({ icon, ...props }) => {
  return (
    <React.Fragment>
      <MediaQuery smallerThan="$md">
        <IconMaterial {...props} size="$sm" icon={icon} />
      </MediaQuery>
      <MediaQuery largerThan="$md">
        <IconMaterial {...props} size="$md" icon={icon} />
      </MediaQuery>
    </React.Fragment>
  );
};

Icons.propTypes = {
  icon: PropTypes.string,
};

Icons.defaultProps = {
  icon: null,
};
