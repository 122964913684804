import { Modal } from '@abyss/web/ui/Modal';
import { Body1, H2 } from '@src/styles/typography/typography';
import { Flex } from '@abyss/web/ui/Flex';
import { Card } from '@src/common/card/card';
import React from 'react';

export const DiscoverSectionContent = [
  {
    title: 'Dental',
    description:
      'Highly configurable products and capabilities to better meet your needs and close gaps in care.',
    buttonText: 'Learn more',
    cardBanner: '/static/bh-dental-card.svg',
    modalContent: (
      <React.Fragment>
        <Modal.Section css={{ textAlign: 'center' }}>
          <H2>Dental benefits focused on whole-body health</H2>
          <Body1
            css={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            We provide members with a simple, accessible experience that
            connects oral and overall health. And our broad range of highly
            configurable products and capabilities are designed to better meet
            your needs.
          </Body1>
        </Modal.Section>
        <Modal.Section css={{}}>
          <Flex
            justify="space-between"
            direction="row"
            css={{
              marginTop: '10px',
              '& > div': {
                marginBottom: '10px',
              },
            }}
          >
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Connected',
                cardBanner: '/static/icons/connection.svg',
                description:
                  'Through outreach, teledentistry, wellness features, a broad network and integrated rewards, we connect people to their oral and overall health.',
              }}
              key="dental_info_card1"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Personalized',
                cardBanner: '/static/icons/toothbrush.svg',
                description:
                  'To help members select the benefit that meets their needs, we offer plans with condition-based benefits, plus a diverse suite of products and prices.',
              }}
              key="dental_info_card2"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Simple',
                cardBanner: '/static/icons/vector.svg',
                description:
                  'Our claimless process and $0 out-of-pocket covered care keeps our members smiling and our single account management team and real-time adjudication helps keep things seamless.',
              }}
              key="dental_info_ard2"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
          </Flex>
        </Modal.Section>

        <Modal.Section css={{}}>
          <H2>By the numbers</H2>
          <Flex direction="row" css={{ gap: '3rem', marginTop: '10px' }}>
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '110k+',
                description: 'Individual network providers',
              }}
              key="dental_numbers_card1"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '27%',
                description:
                  'At-risk members returned to dental care through outreach',
              }}
              key="dental_numbers_card2"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{ cardBanner: '22m', description: 'Dental members' }}
              key="dental_numbers_card4"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '30+',
                description: 'Years of dental benefits experience',
              }}
              key="dental_numbers_card3"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
          </Flex>
        </Modal.Section>
      </React.Fragment>
    ),
  },
  {
    title: 'Vision',
    description:
      'A focus on choice, outcomes and value, with a network of all major retailers plus a wide variety of online shopping options.',
    buttonText: 'Learn more',
    cardBanner: '/static/bh-vision-card.svg',
    modalContent: (
      <React.Fragment>
        <Modal.Section css={{ textAlign: 'center' }}>
          <H2>Vision benefits with a healthy focus</H2>
          <Body1>
            We make vision benefits clearer with more choice and control. And
            our whole-health focus sets its sights on healthier outcomes and
            savings.
          </Body1>
        </Modal.Section>
        <Modal.Section css={{}}>
          <Flex
            justify="space-between"
            direction="row"
            css={{
              marginTop: '10px',
              '& > div': {
                marginBottom: '10px',
              },
            }}
          >
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Clearer outcomes',
                cardBanner: '/static/icons/handshake.svg',
                description:
                  'Proactive support using integrated data to identify those at risk of serious health conditions or frequent vision changes, including mothers and children.',
              }}
              key="card1"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'More choice',
                cardBanner: '/static/icons/vector.svg',
                description:
                  'Expansive and balanced vision network brings members more control and value with a range of price point, location and scheduling options.',
              }}
              key="card2"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Better value',
                cardBanner: '/static/icons/eye.svg',
                description:
                  'Discounts on eyewear, including contact lenses, glasses, blue light filters, laser vision correction and more, from a network of major retailers.',
              }}
              key="card3"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
          </Flex>
        </Modal.Section>
        <Modal.Section css={{}}>
          <H2>By the numbers</H2>
          <Flex direction="row" css={{ gap: '3rem', marginTop: '10px' }}>
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '165k+',
                description: 'Access points nationally — and growing',
              }}
              key="vision_numbers_card1"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '98%',
                description: 'Member network utilization',
              }}
              key="vision_numbers_card2"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{ cardBanner: '25m', description: 'Vision members' }}
              key="vision_numbers_card3"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '57%',
                description: 'At-risk members re-engaged with primary care',
              }}
              key="vision_numbers_card4"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
          </Flex>
        </Modal.Section>
      </React.Fragment>
    ),
  },
  {
    title: 'Hearing',
    description:
      'Competitive offerings designed for ease, choice, convenience and value.',
    buttonText: 'Learn more',
    cardBanner: '/static/bh-hearing-card.svg',
    modalContent: (
      <React.Fragment>
        <Modal.Section css={{ textAlign: 'center' }}>
          <H2>Powerful hearing benefits</H2>
          <Body1>
            We help people on their hearing journey with unmatched ease and
            choice, and our innovative products make the benefit more impactful.
          </Body1>
        </Modal.Section>
        <Modal.Section css={{}}>
          <Flex
            justify="space-between"
            direction="row"
            css={{
              marginTop: '10px',
              '& > div': {
                marginBottom: '10px',
              },
            }}
          >
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Comprehensive',
                cardBanner: '/static/icons/ear.svg',
                description:
                  'Members have access to one of the largest national hearing networks — giving them access to popular hearing aid brands, hearing tests, hearing aid evaluations and follow-up support.',
              }}
              key="hearing_info_card1"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Convenient',
                cardBanner: '/static/icons/clinic.svg',
                description:
                  "Members have access to the care and support that's right for them, from prescription hearing aids with support from hearing professionals to do-it-yourself over-the-counter devices.",
              }}
              key="hearing_info_card2"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
            <Card
              isIcon
              gap="1rem"
              numOfCards={3}
              content={{
                title: 'Supportive',
                cardBanner: '/static/icons/handshake.svg',
                description:
                  'Members have access to tools and resources to help guide them through every stage of their hearing health journey.',
              }}
              key="hearing_info_card3"
              topBgColor="$beige3"
              bottomBgColor="$white"
            />
          </Flex>
        </Modal.Section>
        <Modal.Section css={{}}>
          <H2>By the numbers</H2>
          <Flex direction="row" css={{ gap: '3rem', marginTop: '10px' }}>
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '7k+',
                description: 'National hearing providers',
              }}
              key="hearing_numbers_card1"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '9min',
                description: 'Average drive time to providers',
              }}
              key="hearing_numbers_card2"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '33m',
                description: 'Hearing health members',
              }}
              key="hearing_numbers_card3"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
            <Card
              gap="2rem"
              numOfCards={4}
              content={{
                cardBanner: '25+',
                description:
                  "Years' experience delivering affordable hearing care solutions",
              }}
              key="hearing_numbers_card4"
              topBgColor="$beige3"
              bottomBgColor="$interactive1"
            />
          </Flex>
        </Modal.Section>
      </React.Fragment>
    ),
  },
];
