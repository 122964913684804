import React, { ReactNode } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Container } from '@src/common/layout/container';

type SectionWrapperProps = {
  children?: ReactNode;
  background?: string | string[];
} & typeof Flex;

export const SFlex = styled(Flex, {
  width: '100%',

  variants: {
    background: {
      white: {
        background: '$white',
      },
      beige3: {
        background: '$beige3',
      },
      beige: {
        background: '$beige1',
      },
      linden: {
        backgroundColor: '$linden',
      },
      beigeGradient: {
        background:
          'linear-gradient(to bottom right, $beige2, $beige1, $white, $beige2)',
      },
    },
  },
});

export const SectionWrapper = ({
  children,
  background,
  ...props
}: SectionWrapperProps) => {
  return (
    <SFlex justify="center" background={background} {...props}>
      <Container>{children}</Container>
    </SFlex>
  );
};

SectionWrapper.defaultProps = {
  children: null,
  background: '',
};
