import React, { useState } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Card } from '@src/common/card/card';
import { Flex } from '@abyss/web/ui/Flex';
import { H1 } from '@src/styles/typography/typography';
import { SectionWrapper } from '@src/common/layout/section-wrapper';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { SModal } from '@src/common/modal/modal';
import { Tabs } from '@abyss/web/ui/Tabs';

import { BroadenHealthLogo } from '@src/common/logo/logo';
import { DiscoverSectionContent } from './discover-section.content';

const DISCOVER_CARD_GAP = '4rem';

const LogoContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem 0',
});

export const DiscoverSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContentIndex, setModalContentIndex] = useState(-1);

  const cardClick = (index: number) => {
    setModalContentIndex(index);
    setIsOpen(true);
  };

  return (
    <React.Fragment>
      {modalContentIndex >= 0 && (
        <SModal
          isOpen={isOpen}
          onClose={() => {
            return setIsOpen(false);
          }}
        >
          <LogoContainer>
            <BroadenHealthLogo color="green" variant="header" />
          </LogoContainer>
          <Tabs
            css={{
              'abyss-tabs-content-container': {
                borderColor: '$white',
              },
              'abyss-tabs-list': {
                borderColor: '$white',
              },
              '& .abyss-tabs-tab': {
                borderBottom: '2px solid $gray3',
                '& .abyss-tabs-tab-content': {
                  color: '$primary3',
                },
              },
              '& .abyss-tabs-tab[aria-selected="true"]': {
                '& .abyss-tabs-tab-content': {
                  color: '$primary1',
                },
              },
              'abyss-tabs-tab-active-line': {
                borderColor: '$primary1',
              },
            }}
            size="$lg"
            title="Discover Us"
            grow={false}
            position="center"
            active={modalContentIndex}
            onTabChange={(index: number) => {
              setModalContentIndex(index);
            }}
            variant="line"
          >
            <Tabs.Tab label={DiscoverSectionContent[0].title}>
              {DiscoverSectionContent[0].modalContent}
            </Tabs.Tab>
            <Tabs.Tab label={DiscoverSectionContent[1].title}>
              {DiscoverSectionContent[1].modalContent}
            </Tabs.Tab>
            <Tabs.Tab label={DiscoverSectionContent[2].title}>
              {DiscoverSectionContent[2].modalContent}
            </Tabs.Tab>
          </Tabs>
        </SModal>
      )}
      <SectionWrapper background="linden" css={{ paddingBottom: '36px' }}>
        <H1 css={{ padding: '36px 0', margin: 0 }}>
          Discover our capabilities
        </H1>
        <MediaQuery smallerThan="$md">
          <Flex direction="column" justify="center" css={{ gap: '1rem' }}>
            {DiscoverSectionContent.map((card, index, array) => {
              return (
                <Card
                  numOfCards={Math.min(4, array.length)}
                  content={card}
                  key={card.title}
                  onClick={() => {
                    return cardClick(index);
                  }}
                  bottomBgColor="$beige1"
                />
              );
            })}
          </Flex>
        </MediaQuery>
        <MediaQuery largerThan="$md" css={{ flexGrow: 1 }}>
          <Flex
            direction="row"
            justify="space-between"
            css={{ gap: DISCOVER_CARD_GAP }}
          >
            {DiscoverSectionContent.map((card, index, array) => {
              return (
                <Card
                  gap={DISCOVER_CARD_GAP}
                  numOfCards={Math.min(4, array.length)}
                  content={card}
                  key={card.title}
                  onClick={() => {
                    return cardClick(index);
                  }}
                  bottomBgColor="$beige1"
                />
              );
            })}
          </Flex>
        </MediaQuery>
      </SectionWrapper>
    </React.Fragment>
  );
};
