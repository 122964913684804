import React from 'react';
import { PageHeaderPrimitives } from '@abyss/web/ui/PageHeader';

import { BroadenHealthLogo } from '@src/common/logo/logo';
import { ContainerStyles } from '@src/common/layout/container';
import { breakpoint } from '@src/styles/breakpoints';
import { NavMenu } from './nav-menu';

const PageHeaderContainerStyles = {
  'abyss-page-header-root': {
    zIndex: 100,
    background: '$primary1',
    padding: '$sm 0',
    '> div': {
      padding: '$sm 0',
      justifyContent: 'space-between',
    },
  },
  'abyss-page-header-container': {
    ...ContainerStyles,
    paddingTop: '2rem',
    paddingRight: 0,

    [`${breakpoint.xs}`]: {
      flexDirection: 'column',
    },

    [`${breakpoint.sm}`]: {
      flexDirection: 'row',
    },
  },
};

export const Header = () => {
  return (
    <PageHeaderPrimitives.Container css={PageHeaderContainerStyles}>
      <PageHeaderPrimitives.Brandmark
        logo={<BroadenHealthLogo color="white" />}
      />
      <NavMenu />
    </PageHeaderPrimitives.Container>
  );
};
