import React, { FC, PropsWithChildren } from 'react';
// import { PageBody } from '@abyss/web/ui/PageBody';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';

export type LayoutProps = PropsWithChildren<{
  pageTitle?: string;
}>;

// const PageBodyStyles = {
//   'abyss-page-body-container': {
//     padding: 0,
//     margin: 0,
//     maxWidth: '100%',
//   },
// };

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <Header />
      {/* <PageBody css={PageBodyStyles}>{children}</PageBody> */}
      {children}
      <Footer />
    </div>
  );
};
